<template>
    <div id="page-user-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                    <vs-button
                        @click="addOrganization"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus">
                        {{$t('button.addOrganization')}}
                    </vs-button>
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="organizationList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="20"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererType from "./cell-renderer/CellRendererType.vue"

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        CellRendererStatus,
        CellRendererActions,
        CellRendererType,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 100
                },
                {
                    headerName: this.$t('root.organizationName'),
                    field: 'name',
                    width: 210
                },
                {
                    headerName: this.$t('root.organizationAcronym'),
                    field: 'acronym',
                    width: 210
                },
                {
                    headerName: this.$t('root.organizationType'),
                    field: 'type',
                    width: 210,
                    cellRendererFramework: 'CellRendererType'
                },
                {
                    headerName:  this.$t('root.phoneNumber'),
                    field: 'phoneNumber',
                    width: 200
                },
                {
                    headerName: this.$t('root.status'),
                    field: 'active',
                    width: 200,
                    cellRendererFramework: 'CellRendererStatus'
                },
                {
                    headerName: this.$t('root.actions'),
                    field: 'transactions',
                    width: 120,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererStatus,
                CellRendererActions,
                CellRendererType,
            },
            organizationList: []
        }
    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateOrganization'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_ORGANIZATION_SUCCESS', false);
            }
        },
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addOrganization() {
            this.$router.push('/admin/manage-organization/add').catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$oauth.post('/organization/admin/all').then((response) => {
                this.organizationList = response.data;
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
