<template>
    <span>{{ organizationTypeString(params.value) }}</span>
</template>

<script>
export default {
    name: 'CellRendererType',
    computed: {
        organizationTypeString() {
            return (value) => {
                switch (value) {
                    case 'education':
                        return this.$t('root.education');
                    case 'company':
                        return this.$t('root.company');
                    case 'shop':
                        return this.$t('root.shop');
                    default:
                        return '';
                }
            }
        }
    }
}
</script>
